@use 'styles/variables';
@use 'styles/functions';

.form {
  display: flex;
  flex-direction: column;
  gap: functions.spacing(4);
  max-width: 600px;
  //padding: functions.spacing(4);
  div {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    label {
      font-weight: 600;
      color: variables.$black;
      margin-bottom: functions.spacing(0.5);
      margin-right: functions.spacing(1);
    }
    input {
      color: variables.$primary-5;
      border: none;
      border-bottom: 2px solid variables.$grey;
      padding-top: functions.spacing(0.5);
      padding-bottom: functions.spacing(0.5);
      &:focus {
        border: none;
        outline: none;
        border-bottom: 2px solid variables.$primary-0;
      }
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: variables.$primary-5;
    }
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: variables.$primary-5;
    }
    textarea {
      color: variables.$primary-5;
      border: 2px solid variables.$grey;
      border-radius: 8px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 12px;
      padding-bottom: 12px;
      &:focus {
        border: 2px solid variables.$primary-0;
        outline: none;
      }
    }
    button {
      background-color: variables.$primary-5;
      color: variables.$white;
      font-weight: 600;
      font-size: 16px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 8px;
      border: none;
      box-shadow: 1px 2px 2px rgb(233, 233, 233);
      &:hover {
        background-color: variables.$primary-4;
        box-shadow: 1px 2px 6px #59738f;
      }
    }
  }
}
