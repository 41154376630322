$pink-light: #ffdeee;
$pink-0: #ffbcdc;
$pink-1: #ffabd3;
$pink-2: #fd80bc;
$pink-3: #fb56a5;
$pink-4: #fc54a5; // 3:1 contrast ratio
$pink-45: #fb449c; // 3:1 contrast ratio
$pink-5: #e70472; // 4.5.1 contrast ratio
$blue-0: #bcdcff;
$blue-1: #abd3ff;
$blue-2: #80bcfd;
$blue-3: #56a5fb;
$blue-4: #248bf9; // 3:1 contrast ratio
$blue-5: #0472e7; // 4.5.1 contrast ratio
$green-0: #dcffbc;
$green-1: #d3ffab;
$green-2: #bcff80;
$green-3: #a5fb56;
$orange-0: #ffdfbc;
$orange-1: #ffd7ab;
$orange-2: #fdc180;
$orange-3: #ffab56;
$red-0: #4d4747;
$red-1: #ffadab;
$red-2: #fd8380;
$red-3: #fb5956;

$white: #fff;
$light-grey: #e0dddd;
$medium-grey: #d9d9d9;
$grey: #9e9e9e;
$black: #0f0f0f;

$primary-0: $blue-0;
$primary-1: $blue-1;
$primary-2: $blue-2;
$primary-3: $blue-3;
$primary-4: $blue-4;
$primary-5: $blue-5;

$xs: 400px;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

$increment: 8px;
