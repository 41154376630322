@use 'styles/variables';
@use 'styles/functions';

.section {
  max-width: variables.$lg;

  p {
    margin-bottom: functions.spacing(2);
  }

  padding-bottom: functions.spacing(4);
  a {
    color: variables.$primary-5;
    text-decoration: underline;
  }
}
