@use 'styles/variables';
@use 'styles/functions';

.section {
  max-width: variables.$lg;
  a {
    color: variables.$primary-5;
    text-decoration: underline;
  }
  padding-bottom: functions.spacing(8);
}
