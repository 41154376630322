@use 'styles/variables';
@use 'styles/functions';

.button {
  background-color: variables.$primary-2;
  color: variables.$white;
  font-weight: 600;
  padding-left: functions.spacing(2);
  padding-right: functions.spacing(2);
  padding-top: functions.spacing(1.5);
  padding-bottom: functions.spacing(1.5);
  border-radius: functions.spacing(1);
  border: none;
  box-shadow: 1px 2px 2px rgb(233, 233, 233);
  &:hover {
    background-color: variables.$primary-3;
    color: variables.$white;
  }
}
.pink {
  background-color: variables.$pink-4;
  color: variables.$white;
  &:hover {
    background-color: variables.$pink-45;
  }
}
