@use 'styles/variables';
@use 'styles/functions';

.section {
  max-width: variables.$lg;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  p {
    padding-bottom: functions.spacing(4);
    text-align: center;
    @media (min-width: variables.$sm) {
      padding-left: functions.spacing(4);
      padding-right: functions.spacing(4);
    }
  }
}

.forms {
  & div:nth-child(2) {
    font-weight: bold;
    text-align: center;
  }

  @media (max-width: variables.$md) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    gap: functions.spacing(4);
    > * {
      width: 80vw;
    }
  }

  @media (min-width: variables.$md) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    & div:first-child {
      min-width: 320px;
    }
    & div:nth-child(2) {
      margin-top: 130px;
    }
    & div:last-child {
      min-width: 320px;
    }
  }
}
