@use 'styles/variables';
@use 'styles/functions';

.dl {
  div:not(:last-child) {
    margin-bottom: functions.spacing(2);
    padding-bottom: functions.spacing(2);
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    border-bottom-color: variables.$medium-grey;
  }
  dt {
    label {
      user-select: none;
      cursor: pointer;
      // Remove flashing on tap on mobile
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      span {
        flex-shrink: 100;
      }
    }
  }

  input[type='radio'] {
    display: none;
    & ~ dd {
      visibility: hidden;
      height: 0;
    }
    &:checked ~ dd {
      padding-top: functions.spacing(1);
      visibility: visible;
      margin-inline-start: unset;
      height: unset;
    }
  }
}
