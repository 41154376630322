@use 'styles/variables';
@use 'styles/functions';

.heading {
  h2 {
    text-align: center;
    &.hrVisible {
      margin-bottom: functions.spacing(3);
    }
  }
  hr {
    //visibility: hidden;
    display: none;
    width: functions.spacing(16);
    margin: auto;
    border: 2px solid variables.$primary-3;
    border-radius: functions.spacing(1);
    margin-top: functions.spacing(1);
    margin-bottom: functions.spacing(2);
    &.hrVisible {
      //visibility: visible;
      display: block;
    }
  }
  .pink {
    border-color: variables.$pink-3;
  }
}
