@use 'styles/variables';
@use 'styles/functions';

.section {
  max-width: variables.$xl;

  p {
    margin-bottom: functions.spacing(2);
  }

  img {
    border-radius: functions.spacing(2);
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    //width: 300px;
    height: 350px;
    object-fit: cover;
    object-position: top;

    @media (min-width: variables.$xs) {
      max-width: 220px;
      height: auto;
      width: 40%;
      float: left;
      margin-right: functions.spacing(2);
    }
  }

  .text {
    text-align: left;
    margin-top: functions.spacing(2);
    @media (min-width: variables.$sm) {
      margin-top: unset;
    }
  }
}
