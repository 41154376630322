@use 'styles/variables';
@use 'styles/functions';

.section {
  max-width: variables.$xl;
}

.grid {
  display: grid;
  grid-column-gap: functions.spacing(1);
  grid-row-gap: functions.spacing(1);

  @media (min-width: variables.$md) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
