@use 'styles/variables';
@use 'styles/functions';

.card {
  border-radius: functions.spacing(2);
  box-shadow: 0px 30px 40px -20px variables.$grey;
  max-width: 500px;

  margin-bottom: functions.spacing(2);
  margin-bottom: functions.spacing(2);
  margin-left: functions.spacing(0);
  margin-right: functions.spacing(0);
  @media (min-width: variables.$sm) {
    margin-bottom: functions.spacing(2);
    margin-bottom: functions.spacing(2);
    margin-left: functions.spacing(2);
    margin-right: functions.spacing(2);
  }

  & h3 {
    padding-bottom: functions.spacing(1);
  }

  & p {
    margin-bottom: functions.spacing(1);
  }

  & .contents {
    padding: functions.spacing(3);
  }

  & .contents.pipe p {
    // Enable for vertical paragraph line
    @media (min-width: variables.$sm) {
      border-left-color: variables.$primary-1;
      border-left-width: functions.spacing(0.5);
      border-left-style: solid;
      padding-left: functions.spacing(1);
    }
  }

  &.border {
    border-top-left-radius: functions.spacing(1);
    border-top-right-radius: functions.spacing(1);
    border-top-style: solid;
    border-top-width: functions.spacing(1);

    &.blue {
      border-top-color: variables.$blue-3;
    }
    &.pink {
      border-top-color: variables.$pink-2;
    }
  }
}
