@use 'styles/variables';
@use 'styles/functions';

.section {
  width: 100%;
  max-width: variables.$xl;
  padding-left: functions.spacing(0);
  padding-right: functions.spacing(0);
  text-align: center;
  color: variables.$black;
  h1 {
    text-align: center;
    padding-top: functions.spacing(2);
    padding-bottom: functions.spacing(2);
  }
  p {
    font-size: 1.75em;
  }

  .hero {
    height: 80vh;
    max-height: 800px;
    @media (min-width: variables.$sm) {
      height: 70vh;
    }
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(
        360deg,
        rgb(255 255 255 / 70%) 10%,
        rgb(255 255 255 / 50%) 50%,
        rgb(255 255 255 / 20%) 90%
      );
    }
    img {
      height: 80vh;
      max-height: 800px;
      @media (min-width: variables.$sm) {
        height: 70vh;
      }
      width: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
    }

    .content {
      padding-left: functions.spacing(2);
      padding-right: functions.spacing(2);
      position: relative;
      z-index: 2;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & span {
        padding-bottom: functions.spacing(6);
      }
    }
  }
}
